import React from 'react';
import { graphql } from 'gatsby';

// Hooks
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';
import Layout from '../components/layout';

const IndexPage = ({ data: { page } }) => {
  const { meta, pageBlocks } = page || {};
  return (
    <Layout isHome={true}>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
