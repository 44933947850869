import React from "react"
import PropTypes from "prop-types"

import {
  HomepageBanner,
  ImageGrid,
  PageBanner,
  ProjectCards,
  Services,
  Specifications,
  TextBlock,
  ImageCarousel,
  ImageText,
  ImageBlock,
  VideoBlock,
  Quote,
  TwoColumnText,
  TwoImageText,
  CardGrid
} from './blocks';

const BlockZone = ({ blocks }) => {
  const block = blocks.map(block => {
    switch (block._type) {
      case 'homepageBanner':
        return <HomepageBanner {...block} />;
      case 'imageGrid':
        return <ImageGrid {...block} />;
      case 'imageText':
        return <ImageText {...block} />;
      case 'imageBlock':
        return <ImageBlock {...block} />;
      case 'imageCarousel':
        return <ImageCarousel {...block} />;
      case 'pageBanner':
        return <PageBanner {...block} />;
      case 'projectCards':
        return <ProjectCards {...block} />;
      case 'services':
        return <Services {...block} />;
      case 'specifications':
        return <Specifications {...block} />;
      case 'textBlock':
        return <TextBlock {...block} />;
      case 'quote':
        return <Quote {...block} />;
      case 'twoColumnText':
        return <TwoColumnText {...block} />;
      case 'twoImageText':
        return <TwoImageText {...block} />;
      case 'videoBlock':
        return <VideoBlock {...block} />;
      case 'cardGrid':
        return <CardGrid {...block} />;
      default:
        return null;
    }
  })
  return <>{block}</>
}

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
}

export default BlockZone
